
:root {
	--path-fill-1: #dce3f1;
	--path-fill-2: #869ccc;
	--path-fill-3: #30436f;
	--path-fill-4: #0d1831;
	--color-title: #fff;
	--color-menu: #fff;
	--font-size-menu: 1.625rem;
}

.global-menu {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	z-index: 999;
}

.global-menu__item {
	color: var(--color-menu);
	font-size: var(--font-size-menu);
	opacity: 0;
	transform: translateY(-100%);
	pointer-events: none;
	display: block;
	margin: 0.3em 0;
	transition: transform 0.3s, opacity 0.3s;
	transition-timing-function: ease-in;
}

.global-menu__item:nth-child(odd) {
	transform: translateY(-100%) rotate(10deg);
}

.global-menu__item:nth-child(even) {
	transform: translateY(-100%) rotate(-10deg);
}

.global-menu__item:hover { color: var(--cnvs-themecolor); }

.global-menu__item.is-opened {
	opacity: 1;
	transform: translateY(0) rotate(0);
	pointer-events: auto;
	transition-timing-function: ease;
}

.global-menu__item:nth-of-type(1) {
	transition-delay: 0s;
}

.global-menu__item.is-opened:nth-of-type(1) {
	transition-delay: 0.85s;
}

.global-menu__item:nth-of-type(2) {
	transition-delay: 0.05s;
}

.global-menu__item.is-opened:nth-of-type(2) {
	transition-delay: 0.8s;
}

.global-menu__item:nth-of-type(3) {
	transition-delay: 0.1s;
}

.global-menu__item.is-opened:nth-of-type(3) {
	transition-delay: 0.75s;
}

.global-menu__item:nth-of-type(4) {
	transition-delay: 0.15s;
}

.global-menu__item.is-opened:nth-of-type(4) {
	transition-delay: 0.7s;
}

.shape-overlays {
	width: 100%;
	height: 100vh;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
}

.shape-overlays.is-opened {
	pointer-events: auto;
}

.shape-overlays__path:nth-of-type(1) {
	fill: var(--path-fill-1);
}

.shape-overlays__path:nth-of-type(2) {
	fill: var(--path-fill-2);
}

.shape-overlays__path:nth-of-type(3) {
	fill: var(--path-fill-3);
}

.shape-overlays__path:nth-of-type(4) {
	fill: var(--path-fill-4);
}



.dark .shape-overlays__path:nth-of-type(1) {
	--path-fill-1: #555;
}

.dark .shape-overlays__path:nth-of-type(2) {
	--path-fill-2: #444;
}

.dark .shape-overlays__path:nth-of-type(3) {
	--path-fill-3: #333;
}

.dark .shape-overlays__path:nth-of-type(4) {
	--path-fill-4: #222;
}

@-webkit-keyframes intervalHamburgerBorder {
	0% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	80% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
}

@keyframes intervalHamburgerBorder {
	0% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	80% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
}

.primary-menu-trigger {
    display: block !important;
    opacity: 1 !important;
    pointer-events: auto !important;
    z-index: 1 !important;
}

.hamburger {
	width: 40px;
	height: 40px;
	display: block;
	position: relative;
	cursor: pointer;
	position: fixed;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	top: 40px;
	right: 30px;
	z-index: 1000;
	border-radius: 50%;
	background-color: var(--cnvs-themecolor);
	pointer-events: auto;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

.hamburger::after {
	width: 40px;
	height: 40px;
	box-sizing: border-box;
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	border: 4px solid var(--cnvs-themecolor);
	border-radius: 50%;
	-webkit-animation-duration: 1.2s;
	animation-duration: 1.2s;
	-webkit-animation-name: intervalHamburgerBorder;
	animation-name: intervalHamburgerBorder;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.hamburger i {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	color: #FFF;
	margin: 0;
	padding: 0;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 16px;
	-webkit-transition: opacity .4s ease;
	-o-transition: opacity .4s ease;
	transition: opacity .4s ease;
}

.hamburger.is-opened-navi i:first-child,
.hamburger i:last-child { opacity: 0; }

.hamburger.is-opened-navi i:last-child { opacity: 1; }
